/* @jsx jsx */
import React from 'react';
import Iframe from "@atoms/iframe";
import {jsx, css} from '@emotion/react';
import {unitConverter} from "@utils/base";
const ytVideoCss = css` margin-bottom: ${unitConverter('40px')};`;
const YoutubeVideo = ({id, className}) => (
  <>
    <Iframe src={`https://www.youtube.com/embed/${id}`} css={ytVideoCss} className={className} />
  </>
);

export default YoutubeVideo;
