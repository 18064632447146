/* @jsx jsx */
import React, {useEffect, useState} from 'react';
import {jsx, css} from '@emotion/react';
import {graphql} from "gatsby";
import Layout from "@containers/layout";
import GraphQLErrorList from "@components/graphql-error-list";
import Seo from "@components/seo";
import ContentWrapper from "@molecules/contentWrapper";
import Header from "@organisms/header";
import {mapEdgesToNodes} from "@utils/helpers";
import YoutubeVideo from "@molecules/youtubeVideo";
import {colors, fonts, fontWeights, unitConverter} from "@utils/base";
import PortableText from "@atoms/portableText";
import VideoInfoBar from "@molecules/videoInfoBar";
import YoutubeComments from "@organisms/YoutubeComments/";
import Heading2 from "@atoms/headings/heading2/heading2";
import Heading1 from "@atoms/headings/heading1/heading1";
import YoutubeSubscribeButton from "@molecules/youtubeSubscribe";
import RandomVideos from '@organisms/RandomVideos/index'
const axios = require('axios');
const get = require('lodash.get');

const VideoPage = ({data, errors}) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const {site} = data;
  let {video} = data;
  const {seo, global, header, footer} = site;
  video = mapEdgesToNodes(video)[0];

  const [state, setState] = useState({
    loaded: false,
    statistics: {
      viewCount: 0,
      likeCount: 0,
      dislikeCount: 0,
      favoriteCount: 0,
      commentCount: 0,
    },
    comments: [],
  });

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const descriptionCss = css`
    color: ${colors.text};
    margin-bottom: ${unitConverter('40px')};
    font-family: ${fonts.signo};
    opacity: .6;

    p {
      font-weight: ${fontWeights.light};
    }
  `;

  const titleCss = css`
    margin-bottom: ${unitConverter('40px')};
  `;

  const commentsHeadingCss = css`
    margin-bottom: ${unitConverter('20px')};
  `;

  const subscribeCss = css`
    color: ${colors.text};
    font-family: ${fonts.signo};
    line-height: 1.4;
    margin-bottom: ${unitConverter('20px')};

    div {
      position: relative;
      top: 7px;
      left: 10px;
    }
  `;

  useEffect(() => {
    const getVideoInfo = async () => {
      const result = await axios.post('/.netlify/functions/getVideoInfo', {id: video.ytVideoId});
      result.data.loaded = true;
      setState(result.data);
    };

    getVideoInfo();
  }, [video.ytVideoId]);

  return (
    <Layout footerSettings={footer} bgImage={get(global.backgroundImage, 'asset.fluid.src')}>
      <Seo
        title={video.title}
        description={get(video, '_rawDescription[0].children[0].text', '')}
        keywords={seo.keywords}
        ogType='video'
        image={video.thumbnail}
        meta={[
          {
            property: 'og:video',
            content: `https://www.youtube.com/v/${video.ytVideoId}`
          },
        ]}
      />
      <ContentWrapper>
        <Header title={seo.title} {...header} showSearch={false} logoWidth={400} />
        {video.ytVideoId && <VideoInfoBar {...state.statistics} />}
        {video.ytVideoId && <YoutubeVideo id={video.ytVideoId} />}
        {video.title && <Heading1 css={titleCss}>{video.title}</Heading1>}
        {video._rawDescription && (
          <div css={descriptionCss}>
            <PortableText blocks={get(video, '_rawDescription', [])} />
          </div>
        )}
        {state.comments.length > 0 && (
          <>
            <Heading2 css={commentsHeadingCss}>Comments: </Heading2>
            <YoutubeComments comments={[...state.comments]}/>
          </>
        )}
        <RandomVideos count={4} currentVideoID={video.ytVideoId} />
        <p css={subscribeCss}>
          Don't forget to SUBSCRIBE! <YoutubeSubscribeButton channel="UCD8d3FGC907iS1qiMF0ccxA"/>
        </p>
      </ContentWrapper>
    </Layout>
  );
};

export default VideoPage;

export const query = graphql`
  query VideoPageQuery($slug: String!){
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      seo {
        title
        description
        keywords
      }
      header {
        tagline
        logo {
          asset {
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      global {
        backgroundImage {
          asset {
            id
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      footer {
        address
        tagline
        listOfLinks {
          __typename
          ... on SanityLink {
            linkText
            linkUrl
            _type
          }
        }
      }
    }
    video: allSanityVideo(filter: {slug: {current: {eq: $slug}}}) {
      edges {
        node {
          id
          _rawDescription(resolveReferences: {maxDepth: 10})
          ytVideoId
          pilot {
            _id
            handle
            social {
              facebook
              instagram
            }
            avatar {
              asset {
                _id
                fluid {
                  base64
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
          title
          slug {
            current
          }
          thumbnail {
            asset {
              _id
              fluid {
                base64
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`;
