/* @jsx jsx */
import React from 'react';
import {jsx, css} from '@emotion/react';
import YoutubeComments from "@organisms/YoutubeComments";
import {colors, transitions, unitConverter} from "@utils/base";
import Image from "@atoms/image";
import {decodeHtmlEntity} from "@utils/helpers";

const commentCss = css`
  padding: ${unitConverter('10px')};
  background-color: #333333;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.top-level {
    margin: ${unitConverter('0 0 30px')};
    border-left: ${unitConverter('5px')} solid #444;
    color: #dfdfdf;
  }

  &.reply {
    margin: ${unitConverter('15px 0 10px 0')};
    border-left: ${unitConverter('5px')} solid #222;
    background-color: #121212;
    color: #999;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .comment__content {
    margin-top: 3px;
    line-height: 1.4;
  }
`;

const avatarWrapperCss = css`
  height: ${unitConverter('28px')};
  width: ${unitConverter('28px')};
`;

const avatarCss = css`
  border-radius: 15%/15%;
  max-width: 100%;
`;

const contentCss = css`
  width: calc(100% - ${unitConverter('38px')});
  line-height: 1;

  a {
    color: inherit;
    text-decoration: none;
    transition: all ${transitions.fast} ease-in-out;

    &:hover {
      color: ${colors.text};
    }
  }

  &.top-level > {
    margin-top: 3px;
  }
`;

const commentContentCss = css`
  display: inline;
  padding-left: 10px;
`;

const YoutubeComment = ({id, snippet, replies = {}}) => {
  let commentSnippet;
  const classes = ['comment'];

  if (typeof snippet.topLevelComment !== 'undefined') {
    commentSnippet = snippet.topLevelComment.snippet;
    classes.push('top-level');
  } else {
    commentSnippet = snippet;
    classes.push('reply');
  }

  const {authorDisplayName, textDisplay, authorProfileImageUrl, authorChannelUrl, publishedAt} = commentSnippet;

  return (
    <li className={classes.join(' ')} css={commentCss}>
      <div className="comment__avatar" css={avatarWrapperCss}>
        <a href={authorChannelUrl} target="_blank">
          <Image src={authorProfileImageUrl} css={avatarCss} />
        </a>
      </div>
      <div className="comment__content" css={contentCss}>
        <a href={authorChannelUrl} target="_blank">{authorDisplayName}</a>:
        <div css={commentContentCss} dangerouslySetInnerHTML={{__html: decodeHtmlEntity(textDisplay)}}/>
        {replies.comments && <YoutubeComments comments={[...replies.comments.reverse()]} />}
      </div>
    </li>
  );
};

export default YoutubeComment;
