/* @jsx jsx */
import React from 'react';
import {jsx, css} from '@emotion/react';
import YoutubeComment from "@molecules/YoutubeComment";
import {colors, fonts, fontWeights, unitConverter} from "@utils/base";

const commentsCss = css`
  list-style: none inside;

  li {
    font-size: ${unitConverter('16px')};
    color: ${colors.text};
    font-family: ${fonts.signo};
    font-weight: ${fontWeights.light};
  }

  li > ul {
    margin-left: 20px;
  }
`;

const YoutubeComments = ({comments}) => {
  return (
    <ul className="comments" css={commentsCss}>
      {comments.map(comment => <YoutubeComment key={comment.id} {...comment} />)}
    </ul>
  );
};

export default YoutubeComments;
