import React from 'react'

const FavoritesIcon = ({className}) => (
  <svg version='1.1' x='0px' y='0px' viewBox='0 0 459.699 459.699' className={className}>
    <g className='favorites-icon'>
      <g>
        <path d='M458.028,175.409c-3.984-12.26-14.383-21.027-27.139-22.88l-118.084-17.158L259.997,28.369
          c-5.706-11.56-17.257-18.741-30.148-18.741s-24.443,7.181-30.148,18.741l-52.808,107.002L28.81,152.529
          c-12.757,1.853-23.156,10.62-27.14,22.88c-3.983,12.26-0.724,25.465,8.507,34.464l85.446,83.289L75.452,410.768
          c-2.179,12.706,2.945,25.305,13.375,32.883c5.782,4.199,12.615,6.42,19.762,6.42c5.427,0,10.836-1.336,15.642-3.862
          l105.617-55.526l105.618,55.526c4.805,2.527,10.214,3.862,15.642,3.862c7.148,0,13.981-2.22,19.764-6.42
          c10.429-7.578,15.553-20.177,13.374-32.882l-20.171-117.607l85.447-83.29C458.752,200.874,462.012,187.668,458.028,175.409z
           M427.738,187.527l-91.32,89.014c-3.677,3.584-5.355,8.749-4.487,13.81l21.558,125.692c0.156,0.913-0.211,1.818-0.96,2.361
          c-0.525,0.382-1.063,0.462-1.422,0.462c-0.394,0-0.771-0.093-1.121-0.277l-112.877-59.343c-2.272-1.195-4.766-1.792-7.26-1.792
          s-4.988,0.597-7.262,1.793L109.71,418.59c-0.35,0.185-0.727,0.278-1.122,0.278c-0.358,0-0.897-0.08-1.421-0.461
          c-0.749-0.544-1.116-1.449-0.96-2.362l21.558-125.691c0.868-5.061-0.81-10.226-4.487-13.81l-91.32-89.014
          c-0.663-0.646-0.897-1.595-0.611-2.475c0.287-0.881,1.033-1.511,1.95-1.644l126.2-18.338c5.082-0.739,9.475-3.93,11.748-8.535
          L227.684,42.18c0.41-0.83,1.239-1.346,2.165-1.346c0.926,0,1.755,0.516,2.165,1.346l56.437,114.355
          c2.273,4.605,6.666,7.797,11.748,8.535L426.4,183.408c0.916,0.134,1.663,0.763,1.949,1.644
          C428.635,185.932,428.401,186.881,427.738,187.527z'
        />
      </g>
    </g>
  </svg>
)

export default FavoritesIcon
