import React from 'react'

const ViewsIcon = ({className}) => (
  <svg version='1.1' x='0px' y='0px' viewBox='0 0 31.954 31.954' className={className}>
    <g className='views-icon'>
      <path d='M31.919,15.757c-0.01-0.042-0.004-0.087-0.02-0.128c-0.006-0.017-0.021-0.026-0.027-0.042
        c-0.01-0.024-0.008-0.051-0.021-0.074c-2.9-5.551-9.213-9.528-15.873-9.528c-6.661,0-12.973,3.971-15.875,9.521
        c-0.013,0.023-0.011,0.05-0.021,0.074c-0.007,0.016-0.021,0.025-0.027,0.042c-0.016,0.041-0.01,0.086-0.02,0.128
        C0.017,15.825,0,15.897,0,15.974s0.018,0.148,0.035,0.224c0.01,0.042,0.004,0.087,0.02,0.128c0.006,0.017,0.021,0.026,0.027,0.042
        c0.01,0.024,0.008,0.051,0.021,0.074c2.901,5.551,9.214,9.528,15.875,9.528c6.66,0,12.973-3.971,15.873-9.521
        c0.014-0.023,0.012-0.05,0.021-0.074c0.006-0.016,0.021-0.025,0.027-0.042c0.016-0.041,0.01-0.086,0.02-0.128
        c0.017-0.076,0.035-0.148,0.035-0.224S31.936,15.832,31.919,15.757z M15.977,23.969c-5.615,0-11.112-3.191-13.851-7.995
        c2.754-4.81,8.243-7.99,13.851-7.99c5.613,0,11.111,3.192,13.85,7.995C27.073,20.79,21.585,23.969,15.977,23.969z'
      />
      <path d='M15.977,11.98c-0.002,0-0.004,0.001-0.006,0.001c-2.205,0.004-3.992,1.791-3.992,3.996
        c0,0.276,0.224,0.5,0.5,0.5c0.275,0,0.499-0.224,0.499-0.5c0-1.652,1.345-2.997,2.999-2.997v-0.001
        c0.275,0,0.498-0.224,0.498-0.499C16.475,12.203,16.253,11.98,15.977,11.98z'
      />
      <path d='M15.954,8.981c-3.867,0-7,3.134-7,7s3.134,7,7,7c3.865,0,7-3.135,7-7
        C22.954,12.114,19.819,8.981,15.954,8.981z M15.954,21.981c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6
        S19.263,21.981,15.954,21.981z'
      />
    </g>
  </svg>
)

export default ViewsIcon
