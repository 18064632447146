/* @jsx jsx */
import React from 'react';
import {jsx, css} from '@emotion/react';

const iframeCss = css`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    line-height: 1;
  }
`;

const Iframe = ({src, className}) => (
  <div css={iframeCss} className={className}>
    <iframe
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      autoPlay="autoplay"
    />
  </div>
);

export default Iframe;
