/* @jsx jsx */
import React from 'react';
import {jsx, css} from '@emotion/react';
import Paragraph from "@atoms/paragraph";
import {transitions, unitConverter} from "@utils/base";
import ViewsIcon from "@icons/viewsIcon";
import LikesIcon from "@icons/likesIcon";
import DislikesIcon from "@icons/dislikesIcon";
import FavoritesIcon from "@icons/favoritesIcon";
import CommentsIcon from "@icons/commentsIcon";

const VideoInfoBar = ({viewCount, likeCount, dislikeCount, favoriteCount, commentCount}) => {
  const wrapperCss = css`
    margin-bottom: ${unitConverter('10px')};
    font-size: ${unitConverter('18px')};

    span {
      display: inline-block;
      margin-right: ${unitConverter('10px')};

      &:last-of-type {
        margin-right: 0;
      }
    }
  `;

  const iconCss = css`
    height: ${unitConverter('20px')};
    width: ${unitConverter('20px')};
    position: relative;
    top: ${unitConverter('4px')};
    margin-right: ${unitConverter('5px')};
    fill: yellow;
  `;

  return (
    <Paragraph css={wrapperCss}>
      <span><ViewsIcon css={iconCss} /> Views: {viewCount}</span>
      <span><LikesIcon css={iconCss} /> Likes: {likeCount}</span>
      <span><DislikesIcon css={iconCss} /> Haters: {dislikeCount}</span>
      <span><FavoritesIcon css={iconCss} /> Favorites: {favoriteCount}</span>
      <span><CommentsIcon css={iconCss} /> Comments: {commentCount}</span>
    </Paragraph>
  );
};

export default VideoInfoBar;
