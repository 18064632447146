import React from 'react'

const LikesIcon = ({className}) => (
  <svg version='1.1' x='0px' y='0px' viewBox='0 0 1000 1000' className={className}>
    <g>
      <path d='M198.5,801.5c0-10.2-3.7-19-11.2-26.5c-7.5-7.5-16.3-11.2-26.5-11.2s-19,3.7-26.5,11.2c-7.5,7.5-11.2,16.3-11.2,26.5s3.7,19,11.2,26.5c7.5,7.5,16.3,11.2,26.5,11.2s19-3.7,26.5-11.2C194.7,820.6,198.5,811.7,198.5,801.5z M876.9,462.3c0-20-7.7-37.6-23-52.7c-15.3-15.1-32.8-22.7-52.4-22.7H594.3c0-22.8,9.4-54.1,28.3-93.9c18.8-39.9,28.3-71.4,28.3-94.5c0-38.5-6.3-66.9-18.8-85.4c-12.6-18.5-37.7-27.7-75.4-27.7c-10.2,10.2-17.7,26.9-22.4,50.1c-4.7,23.2-10.7,47.8-18,73.9c-7.3,26.1-18.9,47.6-35.1,64.5c-8.6,9-23.8,26.9-45.3,53.6c-1.6,2-6.1,7.9-13.5,17.7c-7.4,9.8-13.6,17.9-18.5,24.2c-4.9,6.3-11.7,14.6-20.3,25c-8.6,10.4-16.5,19-23.6,25.9c-7.1,6.9-14.7,13.9-22.7,20.9c-8,7.1-15.9,12.4-23.6,15.9c-7.7,3.5-14.6,5.3-20.9,5.3h-18.8v376.9h18.8c5.1,0,11.3,0.6,18.5,1.8c7.3,1.2,13.7,2.5,19.4,3.8c5.7,1.4,13.2,3.5,22.4,6.5c9.2,2.9,16.1,5.2,20.6,6.8s11.5,4,20.9,7.4c9.4,3.3,15.1,5.4,17.1,6.2c82.8,28.7,150,43,201.4,43h71.3c75.4,0,113.1-32.8,113.1-98.3c0-10.2-1-21.2-2.9-33c11.8-6.3,21.1-16.6,28-30.9c6.9-14.3,10.3-28.7,10.3-43.3c0-14.5-3.5-28.1-10.6-40.6c20.8-19.6,31.2-43,31.2-70.1c0-9.8-2-20.7-5.9-32.7c-3.9-12-8.8-21.3-14.7-28c12.6-0.4,23.1-9.6,31.5-27.7c8.4-18,12.7-33.9,12.7-47.7V462.3z M952.3,461.7c0,35-9.6,67-28.9,96c3.5,13,5.3,26.5,5.3,40.6c0,30.2-7.5,58.5-22.4,84.8c1.2,8.2,1.8,16.7,1.8,25.3c0,39.7-11.8,74.6-35.4,104.8c0.4,54.6-16.3,97.7-50.1,129.3C789,974.2,744.4,990,689,990h-76c-37.7,0-74.9-4.4-111.6-13.3c-36.7-8.8-79.2-21.7-127.5-38.6c-45.6-15.7-72.6-23.6-81.3-23.6H123.1c-20.8,0-38.6-7.4-53.3-22.1C55,877.8,47.7,860,47.7,839.2V462.3c0-20.8,7.4-38.6,22.1-53.3s32.5-22.1,53.3-22.1h161.4c14.1-9.4,41-39.9,80.7-91.3c22.8-29.4,43.8-54.6,63-75.4c9.4-9.8,16.4-26.6,20.9-50.4c4.5-23.7,10.5-48.6,18-74.5c7.5-25.9,19.6-47.1,36.5-63.6c15.3-14.5,33-21.8,53-21.8c33,0,62.6,6.4,88.9,19.1c26.3,12.8,46.3,32.7,60.1,59.8c13.8,27.1,20.6,63.6,20.6,109.5c0,36.5-9.4,74.2-28.3,113.1h103.7c40.8,0,76.2,14.9,106,44.8C937.4,386.2,952.3,421.3,952.3,461.7L952.3,461.7z' />
    </g>
  </svg>
)

export default LikesIcon
